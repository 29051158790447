<template>
  <div class="create-content-main">
    <div class="create-form">
      <div class="cpy-2">
        <span class="cpx-4 font-title">URL管理</span>
      </div>
      <form
        class="cpx-15"
        @submit.prevent="CreatePurchasePoint()"
        :disabled="isLoading"
      >
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label">
            以下のコンテンツに関するURLを取得する画面です
          </label>
          <div class="w-100 cp-4">
            <div class="w-75 d-flex">
              <input
                v-model="content.title"
                class="form-input m-0 m-0"
                type="text"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="col col-auto">
          <div class="mx-3">
            <label
              v-for="(item, index) in listSetting"
              :key="index"
              class="mr-3 position-relative py-2"
              :class="
                typeSetting === item.value
                  ? 'btn-tab-sale-active'
                  : 'btn-tab-sale-inactive'
              "
              @click="typeSetting = item.value"
              style="font-weight: normal !important; font-size: 17px"
            >
              {{ item.text }}
            </label>
          </div>
        </div>
        <div v-if="typeSetting === 1">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label">
              初めに新規登録画面が表示されるURL（既存ユーザーの口グインボタン有り）
            </label>
            <div class="d-flex w-100 cp-4">
              <div class="w-75">
                <input
                  v-model="content.url_register"
                  class="form-input m-0 m-0"
                  type="text"
                  disabled
                />
              </div>
              <CButton
                type="button"
                class="btn mx-2 px-4 text-nowrap btn-other"
                color="info"
                square
                variant="outline"
                size="sm"
                v-on:click="copyURL(0)"
              >
                コピー
              </CButton>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label">
              初めに口グイン画面が表示されるURL（新規登録画面のボタン有り）
            </label>
            <div class="d-flex w-100 cp-4">
              <div class="w-75">
                <input
                  v-model="content.url_login"
                  class="form-input m-0 m-0"
                  type="text"
                  disabled
                />
              </div>
              <CButton
                type="button"
                class="btn mx-2 px-4 text-nowrap btn-other"
                color="info"
                square
                variant="outline"
                size="sm"
                v-on:click="copyURL(1)"
              >
                コピー
              </CButton>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label">
              初めに口クイン画面が表示されるURL（新規登録画面のボタンなし）
            </label>
            <div class="d-flex w-100 cp-4">
              <div class="w-75">
                <input
                  v-model="content.url_login_without_register"
                  class="form-input m-0 m-0"
                  type="text"
                  disabled
                />
              </div>
              <CButton
                type="button"
                class="btn mx-2 px-4 text-nowrap btn-other"
                color="info"
                square
                variant="outline"
                size="sm"
                v-on:click="copyURL(2)"
              >
                コピー
              </CButton>
            </div>
          </div>
        </div>
        <div v-else-if="typeSetting === 2">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label">
              基本的な決済URL
            </label>
            <div class="d-flex w-100 cp-4">
              <div class="w-75">
                <input
                  v-model="content.url_sale"
                  class="form-input m-0 m-0"
                  type="text"
                  disabled
                />
              </div>
              <CButton
                type="button"
                class="btn mx-2 px-4 text-nowrap btn-other"
                color="info"
                square
                variant="outline"
                size="sm"
                v-on:click="copyURL(3)"
              >
                コピー
              </CButton>
            </div>
          </div>
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label">
              ストアURL（一度ショップページを介して販売する場合はこちら）
            </label>
            <div class="d-flex w-100 cp-4">
              <div class="w-75">
                <input
                  v-model="content.url_store"
                  class="form-input m-0 m-0"
                  type="text"
                  disabled
                />
              </div>
              <CButton
                type="button"
                class="btn mx-2 px-4 text-nowrap btn-other"
                color="info"
                square
                variant="outline"
                size="sm"
                v-on:click="copyURL(4)"
              >
                コピー
              </CButton>
            </div>
          </div>
        </div>
        <div class="form-group d-flex justify-content-center">
          <button
            type="button"
            @click="returnList"
            class="align-items-center btn btn-secondary btn-round m-3 px-3 back-btn"
          >
            戻る
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "ContentUrl",
  data() {
    return {
      isLoading: false,
      shop_id: parseInt(localStorage.getItem(Constants.SHOP_ID)),
      page: 2,
      id: this.$route.params.id,
      typeSetting: 1,
      listSetting: [
        { value: 1, text: "登録系" },
        { value: 2, text: "販売系" },
      ],
      content: {
        title: "",
        url_register: "",
        url_login: "",
        url_login_without_register: "",
        url_sale: "",
        url_store: "",
      },
    };
  },
  async created() {
    const reqData = {
      id: this.$route.params.id,
      shop_id: this.shop_id,
    };
    await this.getContentById(reqData);
  },
  computed: {
    ...mapGetters(["success", "message", "error", "contentById"]),
  },
  mounted() {
    this.$route.meta.title = this.content.title;
  },
  watch: {
    contentById() {
      this.isEdit = true;
      this.content.title = this.contentById.title;
      this.$store.commit("set", ["contentTitle", this.content.title]);
      this.$store.commit("set", ["contentId", this.content.id]);
      this.content.url_register = this.contentById.url_register_provide
        ? window.location.origin +
          (this.$route.params.shopId
            ? "/shop/" + this.$route.params.shopId
            : "") +
          this.contentById.url_register_provide +
          (this.contentById.is_quick_signup == true ? "&jrg=true" : "")
        : "";
      this.content.url_login = this.contentById.url_login_provide
        ? window.location.origin +
          (this.$route.params.shopId
            ? "/shop/" + this.$route.params.shopId
            : "") +
          this.contentById.url_login_provide +
          "&jlg=false"
        : "";
      this.content.url_login_without_register = this.contentById
        .url_login_provide
        ? window.location.origin +
          (this.$route.params.shopId
            ? "/shop/" + this.$route.params.shopId
            : "") +
          this.contentById.url_login_provide +
          "&jlg=true"
        : "";
      if (
        this.contentById.sale_basic.column &&
        (this.contentById.sale_basic.column[0].product_price ||
          this.contentById.sale_basic.column[0].initialCost)
      ) {
        this.content.url_sale =
          window.location.origin +
          (this.$route.params.shopId
            ? "/shop/" + this.$route.params.shopId
            : "") +
          "/buy/p/" +
          this.contentById.id;
        this.content.url_store =
          window.location.origin +
          (this.$route.params.shopId
            ? "/shop/" + this.$route.params.shopId
            : "") +
          "/store/" +
          this.contentById.id +
          "?keyword=";
      } else {
        this.content.url_sale =
          "販売設定がされていないためURLが取得されません。";
        this.content.url_store =
          "販売設定がされていないためURLが取得されません。";
      }
    },
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
  },
  methods: {
    ...mapActions({
      getContentById: "getContentById",
    }),
    returnList() {
      localStorage.removeItem(Constants.CONTENT);
      this.$router.go(-1);
    },
    copyURL(value) {
      let hasValue = false;
      let text = "";
      if (value == 0) {
        hasValue = true;
        text = this.content.url_register;
      } else if (value == 1) {
        hasValue = true;
        text = this.content.url_login;
      } else if (value == 2) {
        hasValue = true;
        text = this.content.url_login_without_register;
      }
      if (
        this.contentById.sale_basic.column &&
        (this.contentById.sale_basic.column[0].product_price ||
          this.contentById.sale_basic.column[0].initialCost)
      ) {
        if (value == 3) {
          hasValue = true;
          text = this.content.url_sale;
        } else if (value == 4) {
          hasValue = true;
          text = this.content.url_store;
        }
      }
      if (hasValue == true && text) {
        navigator.clipboard.writeText(text);
        this.$store.commit("set", ["message", Constants.MESSAGE_COPY]);
        this.$store.commit("set", ["success", true]);
      } else {
        this.$store.commit("set", [
          "message",
          "販売設定がされていないためURLが取得されません。",
        ]);
        this.$store.commit("set", ["error", true]);
      }
    },
  },
};
</script>

<style lang="scss">
.create-form {
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
  padding: 0px !important;
}

.btn-collapse:after {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid black;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  transform: rotate(180deg);
  transition: all linear 0.25s;
  float: right;
  position: absolute;
  top: 30%;
  right: 10px;
}
button.btn-collapse {
  width: 500px;
  text-align: left;
  position: relative;
}
.btn-collapse.close-collapse:after {
  transform: rotate(0deg);
}
.title-content {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  @media (max-width: 767px) {
    display: block;
    margin-left: 0;
  }
  div {
    button {
      float: right;
      margin-left: 10px;
      @media (max-width: 767px) {
        float: none;
        margin-left: 0;
        display: block;
        margin-top: 10px;
      }
    }
  }
}
.title-btn {
  display: flex;
  margin-top: 20px;
  align-items: center;
  @media (max-width: 767px) {
    display: block;
    button {
      @media (max-width: 767px) {
        display: block;
        margin-top: 10px;
      }
    }
  }
}
.radio-type {
  display: flex;
  @media (max-width: 767px) {
    display: block;
  }
}
.tax-fee {
  display: flex;
  @media (max-width: 767px) {
    display: block;
  }
}
.btn-tab-sale-active {
  background: #ff644e;
  border: none;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.75rem 1.5rem;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 3px;
  width: 160px;
}
.btn-tab-sale-inactive {
  color: #636f83;
  border-color: #636f83;
  display: inline-block;
  font-weight: 400;
  color: #3c4b64;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #636f83;
  padding: 0.75rem 1.5rem;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 3px;
  width: 160px;
}
.btn-tab-sale-active:after {
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
  content: "";
  display: block;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ff644e;
}
.btn-toggle-active {
  display: inline-block;
  position: relative;
  .btn-checkbox-active {
    border: 0;
  }
  input[type="radio"] {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    inset: 0;
    &:checked ~ .btn-checkbox-active {
      background: #ff644e;
      color: #fff;
    }
  }
}
.btn-toggle-active {
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
}
.edit-content-main {
  label {
    // margin-bottom: 0;
  }
  .custom-button {
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: #595959;
    border-radius: 50%;
    border-color: #595959;
    display: block;
    margin-left: 1%;
    position: relative;
    padding: 0;
    .icon {
      width: 40px;
      height: 40px;
    }
  }
}
.page-list-content {
  padding: 0px;
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0px !important;
  }
}
.custom-label {
  border: 1px solid #b5b5b5;
  padding: 20px;
  width: 100%;
  text-align: left;
  margin: 0px;
}
.customBackGround {
  border-radius: 25px;
  background-color: #d9d9d9;
}
/deep/ .modalCustom > .modal-dialog {
  max-width: 720px !important;
  margin: 1.75rem auto;
}
.customSelectAdd {
  border: 1px solid #d9d9d9;
  background-color: #d9d9d9;
}
.border-titlePage {
  text-align: center;
  padding: 5px 30px 5px 30px;
  border: 2px solid #828282;
  margin-top: revert;
  margin-bottom: revert;
  background-color: #f2f2f2;
}
.customListPage {
  .custom-button {
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: #595959;
    border-radius: 50%;
    border-color: #595959;
    display: block;
    margin-left: 1%;
    position: relative;
    .custom-span {
      position: absolute;
      top: -61%;
      right: 25.6%;
      font-size: 50px;
    }
  }
}
.textPage {
  width: 95%;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.custom-button-add-chapter {
  margin: 0px 20px 1.5rem;
  background-color: #0083cf;
  border-color: #0083cf;
  color: #fff;
}
// .custom-button-add-chapter:hover {
//   margin: 0px 20px 1.5rem;
//   background-color: #0083cf;
//   border-color: #0083cf;
//   color: #fff;
// }
.custom-button-add-chapter:not(:disabled):not(.disabled):active,
.show > .custom-button-add-chapter.dropdown-toggle {
  // margin: 0px 20px 1.5rem;
  background-color: #0083cf;
  border-color: #0083cf;
  color: #fff;
}
.cropper-component {
  width: 100%;
  height: 300px;
  margin: 30px auto;
}
.show-preview {
  border: 1px solid #000;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: whitesmoke;
  background-size: cover;
  width: 300px;
  height: 300px;
}
@media (max-width: 1024px) {
  .btn-tab-sale-inactive {
    padding: 0.75rem 1rem;
    font-size: 18px;
    width: 110px;
  }
  .btn-tab-sale-active {
    padding: 0.75rem 1rem;
    font-size: 18px;
    width: 110px;
  }
}
@media (max-width: 450px) {
  .btn-tab-sale-inactive {
    margin-top: 10px;
  }
  .btn-tab-sale-active {
    margin-top: 10px;
  }
  .btn-return-respon {
    margin-top: 5px;
  }
}
.sale-btn {
  width: 120px !important;
  height: 42px !important;
  background-color: #00b797 !important;
  color: #fff !important;
  border-radius: 6px !important;
  font-size: 20px;
}
.sale-btn:hover {
  width: 120px !important;
  height: 42px !important;
  background-color: #00ab82 !important;
  color: #fff !important;
  border-radius: 6px !important;
}
.select-display {
  width: 500px;
  overflow-y: auto;
}
.editor-collapse {
  .row-input input {
    margin: unset !important;
  }
  .cdx-search-field__input {
    height: unset !important;
    margin: unset !important;
    width: unset !important;
  }
}

.btn-tab-sale-active {
  background: #ff644e;
  border: none;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.75rem 1.5rem;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 3px;
  width: 160px;
}
.btn-tab-sale-inactive {
  color: #636f83;
  border-color: #636f83;
  display: inline-block;
  font-weight: 400;
  color: #3c4b64;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #636f83;
  padding: 0.75rem 1.5rem;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 3px;
  width: 160px;
}
.btn-tab-sale-active:after {
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
  content: "";
  display: block;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ff644e;
}

@media (max-width: 1024px) {
  .btn-tab-sale-inactive {
    padding: 0.75rem 1rem;
    font-size: 18px;
    width: 110px;
  }
  .btn-tab-sale-active {
    padding: 0.75rem 1rem;
    font-size: 18px;
    width: 110px;
  }
}
@media (max-width: 450px) {
  .btn-tab-sale-inactive {
    margin-top: 10px;
  }
  .btn-tab-sale-active {
    margin-top: 10px;
  }
}
</style>
